@font-face {
	font-family: 'mabry-pro-light';
	font-display: fallback;
	src:
		url('./fonts/MabryPro-Light.woff') format('woff'),
		url('./fonts/MabryPro-Light.woff2') format('woff2');
}

@font-face {
	font-family: 'mabry-pro-regular';
	font-display: fallback;
	src:
		url('./fonts/MabryPro-Regular.woff') format('woff'),
		url('./fonts/MabryPro-Regular.woff2') format('woff2');
}

@font-face {
	font-family: 'mabry-pro-medium';
	font-display: fallback;
	src:
		url('./fonts/MabryPro-Medium.woff') format('woff'),
		url('./fonts/MabryPro-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'mabry-pro-bold';
	font-display: fallback;
	src:
		url('./fonts/MabryPro-Bold.woff') format('woff'),
		url('./fonts/MabryPro-Bold.woff2') format('woff2');
}

@font-face {
	font-family: 'gt-america-regular';
	font-display: fallback;
	src:
		url('./fonts/gt-america-regular.woff') format('woff'),
		url('./fonts/gt-america-regular.woff2') format('woff2');
}

@font-face {
	font-family: 'gt-america-medium';
	font-display: fallback;
	src:
		url('./fonts/gt-america-medium.woff') format('woff'),
		url('./fonts/gt-america-medium.woff2') format('woff2');
}

@font-face {
	font-family: 'inter-regular';
	font-weight: normal;
	font-display: fallback;
	src: url('./fonts/Inter-Regular.eot');
	src:
		url('./fonts/Inter-Regular.woff2') format('woff2'),
		url('./fonts/Inter-Regular.woff') format('woff'),
		url('./fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'inter-medium';
	font-weight: 500;
	font-display: fallback;
	src: url('./fonts/Inter-Medium.eot');
	src:
		url('./fonts/Inter-Medium.woff2') format('woff2'),
		url('./fonts/Inter-Medium.woff') format('woff'),
		url('./fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'inter-bold';
	font-weight: bold;
	font-display: fallback;
	src: url('./fonts/Inter-Bold.eot');
	src:
		url('./fonts/Inter-Bold.woff2') format('woff2'),
		url('./fonts/Inter-Bold.woff') format('woff'),
		url('./fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'pp-object-sans-black';
	font-display: fallback;
	src:
		url('./fonts/PPObjectSans-Black.otf') format('opentype');
}

@font-face {
	font-family: 'pp-object-sans-bold';
	font-display: fallback;
	src:
		url('./fonts/PPObjectSans-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'pp-object-sans-heavy';
	font-display: fallback;
	src:
		url('./fonts/PPObjectSans-Heavy.otf') format('opentype');
}

@font-face {
	font-family: 'pp-object-sans-medium';
	font-display: fallback;
	src:
		url('./fonts/PPObjectSans-Medium.otf') format('opentype');
}

@font-face {
	font-family: 'pp-object-sans-regular';
	font-display: fallback;
	src:
		url('./fonts/PPObjectSans-Regular.otf') format('opentype');
}

@font-face {
	font-family: 'mabry-mono-pro-regular';
	src: url("./fonts/mabry-mono-regular-pro.otf") format('opentype');
  }
  
  @font-face {
	font-family: 'mabry-mono-pro-bold';
	src: url("./fonts/mabry-mono-bold-pro.otf") format('opentype');
  }
  
  @font-face {
	font-family: 'mabry-mono-pro-medium';
	src: url("./fonts/mabry-mono-medium-pro.otf") format('opentype');
  }